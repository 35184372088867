// import PropTypes from "prop-types"
import React from "react"

import FacebookIcon from "./facebook_icon"
import TwitterIcon from "./twitter_icon"
import InstagramIcon from "./instagram_icon"
import TiktokIcon from "./tiktok_icon"

import "./footer.scss"

const Footer = () => (
  <footer className="Footer">
    <ul className="SocialMenu">
      <li className="SocialMenuItem">
        <a
          href="https://www.facebook.com/jadohlearning"
          // target="_blank"
          // rel="noopener noreferrer"
        >
          <FacebookIcon fill="#fff" size="1.4rem" />
        </a>
      </li>
      <li className="SocialMenuItem">
        <a
          href="https://twitter.com/JADOHLearning"
          // target="_blank"
          // rel="noopener noreferrer"
        >
          <TwitterIcon fill="#fff" size="1.4rem" />
        </a>
      </li>
      <li className="SocialMenuItem">
        <a
          href="https://www.instagram.com/jadohlearning/"
          // target="_blank"
          // rel="noopener noreferrer"
        >
          <InstagramIcon fill="#fff" size="1.4rem" />
        </a>
      </li>
      <li className="SocialMenuItem">
        <a
          href="https://www.tiktok.com/@jadohlearning"
          // target="_blank"
          // rel="noopener noreferrer"
        >
          <TiktokIcon fill="#fff" size="1.4rem" />
        </a>
      </li>
    </ul>
    <hr
      style={{
        borderWidth: "1px",
        borderColor: "#e93e3e",
        borderBottom: "none",
      }}
    />
    © {new Date().getFullYear()} JADOH Learning
  </footer>
)

// Footer.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Footer.defaultProps = {
//   siteTitle: ``,
// }

export default Footer
