import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React, { useState, useContext } from "react"
import { useTranslation } from "react-i18next"
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  NavbarToggler,
  Collapse,
} from "reactstrap"

import LocalizedLink from "./localized_link"
import SmoothScrollContext from "../smoothScrollContext"

import JadohLogoSVG from "../images/jadoh_logo.svg"
import USFlagImage from "../images/flag/united-states.png"
import THFlagImage from "../images/flag/thailand.png"

import locales from "../../config/i18n"

import "./header.scss"

function getPathnameWithoutLocale(pathname, currentLocale) {
  if (locales[currentLocale].default) {
    return pathname
  } else {
    const pathWithoutLocale = pathname
      .split("/")
      .splice(2)
      .join("/")
    return pathWithoutLocale === "" ? "/" : `/${pathWithoutLocale}`
  }
}

const Header = ({ location, locale }) => {
  const { t } = useTranslation()

  const smoothScroll = useContext(SmoothScrollContext)

  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)
  const closeNavBar = () => setIsOpen(false)
  const scrollTo = (path, anchorSelector) => event => {
    const matchPath = getPathnameWithoutLocale(location.pathname, locale)
    if (path === matchPath) {
      event.preventDefault()
      const navIsCurrentlyOpen = isOpen
      setIsOpen(false)
      let scrollFn
      if (anchorSelector === "top") {
        scrollFn = () => smoothScroll.animateScroll(0)
      } else {
        const anchor = document.querySelector(anchorSelector)
        scrollFn = () =>
          smoothScroll.animateScroll(anchor, null, {
            offset: 50,
          })
      }
      if (navIsCurrentlyOpen) {
        setTimeout(scrollFn, 250)
      } else {
        scrollFn()
      }
    } else {
      setIsOpen(false)
    }
  }

  return (
    <Navbar dark sticky="top" expand="md" className="Navbar">
      <NavbarBrand tag={LocalizedLink} to="/" onClick={closeNavBar}>
        <img
          src={JadohLogoSVG}
          width="30"
          height="30"
          className="d-inline-block align-top"
          alt=""
          loading="lazy"
        />{" "}
        JADOH
      </NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ml-auto" navbar>
          <NavItem>
            <NavLink tag={LocalizedLink} to="/" onClick={scrollTo("/", "top")}>
              {t("nav.getTheApp")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag={LocalizedLink}
              to="/#features"
              onClick={scrollTo("/", "#features")}
            >
              {t("nav.features")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag={LocalizedLink}
              to="/#vision"
              onClick={scrollTo("/", "#vision")}
            >
              {t("nav.vision")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag={LocalizedLink}
              to="/#people"
              onClick={scrollTo("/", "#people")}
            >
              {t("nav.people")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={LocalizedLink} to="/corporate" onClick={closeNavBar}>
              {t("nav.corporateClients")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={LocalizedLink} to="/contact" onClick={closeNavBar}>
              {t("nav.contactUs")}
            </NavLink>
          </NavItem>

          {locale !== "en" && (
            <NavItem>
              <NavLink
                tag={Link}
                to={getPathnameWithoutLocale(location.pathname, locale)}
                onClick={closeNavBar}
              >
                <img className="LanguageSelectImage" src={USFlagImage} />
              </NavLink>
            </NavItem>
          )}
          {locale !== "th" && (
            <NavItem>
              <NavLink
                tag={Link}
                to={`/th${getPathnameWithoutLocale(location.pathname, locale)}`}
                onClick={closeNavBar}
              >
                <img className="LanguageSelectImage" src={THFlagImage} />
              </NavLink>
            </NavItem>
          )}
        </Nav>
      </Collapse>
    </Navbar>
  )
}

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default Header
