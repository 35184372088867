import React, { useContext } from "react"
import { Link } from "gatsby"
import LocaleContext from "../localeContext"
import locales from "../../config/i18n"

// Use the globally available context to choose the right path
const LocalizedLink = ({ to, ...props }) => {
  const { locale } = useContext(LocaleContext)

  const startsAtRoot = to.startsWith("/")

  // If it's the default language, don't do anything
  // If it's another language, add the "path"
  const path = locales[locale].default
    ? to
    : `${startsAtRoot ? `/${locales[locale].path}${to}` : to}`

  return <Link {...props} to={path} />
}

export default LocalizedLink
