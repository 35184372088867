// Only one item MUST have the "default: true" key

module.exports = {
  en: {
    default: true,
    path: `en`,
    locale: `en-US`,
    dateFormat: `DD/MM/YYYY`,
    siteLanguage: `en`,
    ogLanguage: `en_US`,
  },
  th: {
    path: `th`,
    locale: `th-TH`,
    dateFormat: `DD/MM/YYYY`,
    siteLanguage: `th`,
    ogLanguage: `th_TH`,
  },
}
