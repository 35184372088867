/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useStaticQuery, graphql } from "gatsby"

import SmoothScrollContext from "../smoothScrollContext"

import Header from "../components/header"
import Footer from "../components/footer"
import "../css/typography.css"
import "./layout.scss"

import withI18next from "../i18n/withI18next"

let SmoothScroll
if (typeof window !== "undefined") {
  SmoothScroll = require("smooth-scroll")
}

const Layout = ({ children, location, pageContext: { locale } }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const { i18n } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage(locale)
  }, [location, i18n, locale])

  const [smoothScroll, setSmoothScroll] = useState(null)

  useEffect(() => {
    if (SmoothScroll) {
      setSmoothScroll(new SmoothScroll())
    }
  }, [])

  return (
    <>
      <SmoothScrollContext.Provider value={smoothScroll}>
        <Header
          siteTitle={data.site.siteMetadata.title}
          location={location}
          locale={locale}
        />
        <main className="Main">{children}</main>
        <Footer />
      </SmoothScrollContext.Provider>
    </>
  )
}

export default withI18next()(Layout)
