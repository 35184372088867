module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"JADOH Learning","short_name":"Jadoh","start_url":"/","background_color":"#f26d6d","theme_color":"#f26d6d","display":"minimal-ui","icon":"src/images/jadoh-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"96b9108fc8668b9cf41d4ed683d74092"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
